<template>
    <el-dialog
        :title="$t('services.add_to_history')"
        :visible.sync="modalAddHistory"
        :before-close="closeModal"
        width="590px"
        top="40px"
        :close-on-click-modal="false"
        @closed="$emit('closed')"
    >
        <el-form
            ref="formData"
            v-loading="$wait.is('modal.loading.*')"
            element-loading-spinner="el-custom-spinner"
            :model="formData"
            :rules="rules"
            label-position="top"
        >
            <el-row :gutter="20">
                <el-col :span="20" :offset="2">
                    <el-form-item :label="$t('services.employee')" prop="selectedEmployee">
                        <el-select v-model="formData.selectedEmployee" :placeholder="$t('services.employees')" filterable class="w-full">
                            <el-option-group :label="$t('services.active')">
                                <el-option
                                    v-for="employee in activeEmployeesData"
                                    :key="employee.uuid"
                                    :label="employee.name + ' ' + employee.surname"
                                    :value="employee.uuid"
                                />
                            </el-option-group>
                            <el-option-group :label="$t('services.archived')">
                                <el-option
                                    v-for="employee in archivedEmployeesData"
                                    :key="employee.uuid"
                                    :label="employee.name + ' ' + employee.surname"
                                    :value="employee.uuid"
                                />
                            </el-option-group>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="20" :offset="2">
                    <el-form-item :label="$t('services.client_address')" prop="selectedAddress">
                        <el-select v-model="formData.selectedAddress" :placeholder="$t('calendar.client_address')" filterable class="w-full">
                            <el-option
                                v-for="address in addresses"
                                :key="address.uuid"
                                :value="address.uuid"
                                :label="address.client + ' - ' + address.address"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="20" :offset="2">
                    <el-form-item :label="$t('services.worked_date')" prop="startDate">
                        <el-date-picker
                            v-model="workedDate"
                            :clearable="false"
                            :editable="false"
                            :picker-options="pickerOptions"
                            :placeholder="$t('services.worked_date')"
                            format="dd.MM.yyyy"
                        />
                    </el-form-item>
                </el-col>

                <el-col :span="10" :offset="2">
                    <el-form-item :label="$t('services.start_time')" prop="startTime">
                        <el-time-select
                            v-model="formData.startTime"
                            placeholder="Start time"
                            :clearable="false"
                            :picker-options="{
                                start: '00:00',
                                end: '23:45',
                                step: '00:15',
                            }"
                            @change="startTimeChanged"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="10">
                    <el-form-item :label="$t('services.end_time')" prop="endTime">
                        <el-time-select
                            v-model="formData.endTime"
                            placeholder="End time"
                            :clearable="false"
                            :picker-options="{
                                start: '00:00',
                                end: '23:45',
                                step: '00:15',
                                minTime: formData.startTime
                            }"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button :disabled="$wait.is('adding')" @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('adding')" @click="addToHistory">{{ $t('common.add') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Vue from 'vue';
import { TimeSelect } from 'element-ui';

Vue.use(TimeSelect);

export default {
    props: {
        modalAddHistory: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            activeEmployeesData:   [],
            archivedEmployeesData: [],
            addresses:             [],
            workedDate:            this.$dayjs(),
            formData:              {
                selectedEmployee: null,
                selectedAddress:  null,
                startTime:        '12:00',
                endTime:          '14:00',
            },
            rules: {
                selectedEmployee: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                selectedAddress:  [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                startTime:        [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                endTime:          [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
            pickerOptions: {
                firstDayOfWeek: 1,
                disabledDate:   date => date.getTime() > this.$dayjs(),
            },
            timePickerOptions: {
                step: '00:15',
            },
        };
    },

    created() {
        this.getActiveEmployees();
        this.getArchivedEmployees();
        this.getAddresses();
    },

    methods: {

        async getActiveEmployees() {
            this.$wait.start('loading.active_employees');

            const employees = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees`);
            this.activeEmployeesData = employees.data;

            this.$wait.end('loading.active_employees');
        },

        async getArchivedEmployees() {
            this.$wait.start('loading.archived_employees');

            const employees = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/archived`);
            this.archivedEmployeesData = employees.data;

            this.$wait.end('loading.archived_employees');
        },

        async getAddresses() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/addresses/clients_first`);
            this.addresses = data;
            this.$wait.end('modal.loading.addresses');
        },

        startTimeChanged() {
            if (this.formData.startTime >= this.formData.endTime) {
                this.formData.endTime = null;
            }
        },

        async addToHistory() {
            this.$refs.formData.validate(async valid => {
                if (!valid) return false;
                this.$wait.start('adding');

                const workedDate = this.$dayjs(this.workedDate);
                const startTime = this.$dayjs(this.formData.startTime, 'HH:mm');
                const endTime = this.$dayjs(this.formData.endTime, 'HH:mm');
                const duration = endTime.diff(startTime, 'minute');

                try {
                    await axios.post(`${process.env.VUE_APP_CORE_API_URL}/services/service_history`, {
                        employeeUuid: this.formData.selectedEmployee,
                        addressUuid:  this.formData.selectedAddress,
                        started_at:   workedDate.hour(startTime.hour()).minute(startTime.minute()).unix(),
                        duration,
                    });
                    this.$wait.end('adding');
                    this.$emit('refresh');
                    this.closeModal();
                } catch (error) {
                    this.$wait.end('adding');
                }
            });
        },

        closeModal() {
            this.$emit('close');
        },
    },
};
</script>
